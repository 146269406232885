import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardData = [
  {
    index: 1,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Cosmetic+Dentistry.png",
    heading: "Cosmetic Dentistry",
    content:
      "Cosmetic dentistry focuses on enhancing the aesthetic appearance of teeth and smiles.",
  },
  {
    index: 2,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Invisalign+Treatment.png",
    heading: "Invisalign Treatment",
    content:
      "Invisalign offers a discreet alternative to traditional braces, utilizing clear aligners to gradually straighten teeth.",
  },
  {
    index: 3,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Dental+Implants.png",
    heading: "Dental Implants",
    content:
      "Dental implants are a durable and natural-looking solution for replacing missing teeth.",
  },
  {
    index: 4,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Prosthodontics%2C+Crown%2C+and+Bridge.png",
    heading: "Prosthodontics, Crown, and Bridge",
    content:
      "Prosthodontics involves the design and placement of dental prosthetics, including crowns and bridges.",
  },
  {
    index: 5,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Orthodontics+And+Clear+Aligners.png",
    heading: "Orthodontics And Clear Aligners",
    content:
      "Orthodontics treatments, including clear aligners, address misalignment issues to improve bite and overall dental health.",
  },
  {
    index: 6,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Paediatric+Dentistry-1.png",
    heading: "Paediatric Dentistry",
    content:
      "Specialized care for children's dental health. Treatment is tailored to the unique needs of young patients, promoting a lifetime of good oral habits.",
  },
  {
    index: 7,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Root+Canal+Treatment+or+Endodontics.png",
    heading: "Root Canal Treatment or Endodontics",
    content:
      "Endodontic procedures, such as root canal treatment, address issues within the tooth pulp to alleviate pain and save natural teeth. ",
  },
  {
    index: 8,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Paediatric+Dentistry.png",
    heading: "Advanced Gum Treatments",
    content:
      "Advanced gum treatments encompass a range of procedures to address gum diseases and improve gum health.",
  },
];

const Carosuel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is mobile

  const cardCount = isMobile ? 1 : 4;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: cardCount,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "100px 0px",
        // border: "1px solid black",
      }}
    >
      {/* header section */}
      <Box
        sx={{
          width: ["100%", "70%"],
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: ["24px", "60px"],
            lineHeight: ["32px", "73px"],
            textAlign: "center",
            fontWeight: "700",
            padding: "0px 10px",
          }}
        >
          Creating smiles that last a lifetime – your dental health is our
          priority.
        </Typography>
      </Box>
      {/* subheader section */}
      <Box
        sx={{
          width: ["100%", "70%"],
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: ["19px", "20px"],
            lineHeight: ["30px", "22px"],
            textAlign: "center",
            padding: "0px 10px",
          }}
        >
          Empowered by a team of highly skilled dentists, cutting-edge
          facilities, advanced treatment options, and exceptional dental care.
        </Typography>
      </Box>
      {/* carousel section */}
      <Box
        sx={{
          width: ["90%", "80%"],
          marginTop: "60px",
          // border: "1px solid red",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: 1,
          backgroundColor: "white",
        }}
      >
        <Slider {...settings}>
          {CardData?.map((item, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid var(--CTA---Aqua-Squeeze, #E5F4F2)",
                boxShadow:
                  "34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05)",
                borderRadius: "20px",
                height: { xs: "350px", md: "330px" },
                backgroundColor: "#f2f2f2",
                width: ["100%", "95% !important"],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  // gap: 3,
                  justifyContent: "space-around",
                  marginTop: "20px",
                  // backgroundColor: "white",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--Neutral-900, #111827)",
                    fontFamily: "Nunito",
                    fontSize: "22px",
                    fontWeight: 700,
                    width: "40%",
                    padding: "30px 0px",
                    // border: "1px solid red",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  {item.heading}
                </Typography>
                <Box
                  sx={{
                    height: "160px",
                    width: "160px",
                    // textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // border: "1px solid red",
                  }}
                >
                  <img style={{ height: "80%" }} src={item.img} alt="icon" />
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#000000",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                  fontWeight: 400,
                  textAlign: "left",
                  padding: "0px 30px",
                  marginTop: "10px",
                }}
              >
                {item.content}
              </Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Carosuel;
