import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const data = [
  {
    index: 1,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/image1.png",
    heading: "Explore the forefront of dental technology",
    subHeading:
      "We seamlessly integrate the latest innovations in dentistry for precise diagnoses and procedures. Immerse yourself in the future of dental care with our cutting-edge technologies, including Cerec CAD CAM, 3D CT Scan, Digital Smile Design, and other state-of-the-art advancements.",
  },
  {
    index: 2,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/image2.png",
    heading: "Making an informed choice",
    subHeading:
      "At YORE Care, we prioritize empowering patients to make informed choices about their healthcare journey. We are committed to ensuring that every individual is well-informed about their condition and the available options. To facilitate this, we present a range of alternative treatment plans tailored to accommodate your budget, time constraints, and any other specific needs.",
  },
  {
    index: 3,
    img: "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/image3.png",
    heading: "Ensuring safety through uncompromising hygiene",
    subHeading:
      "At YORE Care, we prioritize your safety above all else. Our commitment to maintaining impeccable hygiene is underscored by stringent sterilization protocols. As an internationally certified setup, we continuously monitor and uphold the highest standards to ensure your well-being.",
  },
];

const WhyVisit = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const height = isMobile ? "208px" : "361px";
  const width = isMobile ? "326px" : "491px";

  return (
    <Box
      sx={{
        margin: "auto",
      }}
    >
      {/* header */}
      <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: ["26px", "48px"],
            lineHeight: "41px",
            fontWeight: "700",
            color: "#303030",
            margin: "20px 0px 60px 0px",
          }}
        >
          WHY you must visit INDIA…?
        </Typography>
      </Box>
      {/* Container */}
      <Box
        sx={{
          width: ["100%", "70%"],
          margin: "auto",
        }}
      >
        {data.map((elem, i) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              gap: ["20px", "100px"],
              margin: ["20px 0px", "50px 0px"],
            }}
          >
            {elem.index % 2 === 0 ? (
              <>
                {isMobile ? (
                  <>
                    {/* image container */}
                    <Box sx={{ margin: "auto" }}>
                      <img
                        height={height}
                        width={width}
                        sx={{
                          borderRadius: "10px",
                        }}
                        src={elem.img}
                        alt="imageContainer"
                      />
                    </Box>
                    {/* text container */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        textAlign: ["center", "left"],
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "Nunito",
                            fontSize: ["24px", "32px"],
                            lineHeight: ["32px", "41px"],
                            fontWeight: "700",
                            color: "#303030",
                          }}
                        >
                          {elem.heading}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: ["0px 15px", "0px"],
                          textAlign: ["justify", "left"],
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Nunito",
                            fontSize: ["18px", "24px"],
                            lineHeight: ["30px", "36px"],
                            color: "#454040",
                          }}
                        >
                          {elem.subHeading}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    {/* text container */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        textAlign: ["center", "left"],
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "Nunito",
                            fontSize: ["24px", "32px"],
                            lineHeight: ["32px", "41px"],
                            fontWeight: "700",
                            color: "#303030",
                          }}
                        >
                          {elem.heading}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: ["0px 15px", "0px"],
                          textAlign: ["justify", "left"],
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Nunito",
                            fontSize: ["18px", "24px"],
                            lineHeight: ["30px", "36px"],
                            color: "#454040",
                          }}
                        >
                          {elem.subHeading}
                        </Typography>
                      </Box>
                    </Box>
                    {/* image container */}
                    <Box sx={{ margin: "auto" }}>
                      <img
                        height={height}
                        width={width}
                        sx={{
                          borderRadius: "10px",
                        }}
                        src={elem.img}
                        alt="imageContainer"
                      />
                    </Box>
                  </>
                )}
              </>
            ) : (
              <>
                {/* image container */}
                <Box sx={{ margin: "auto" }}>
                  <img
                    height={height}
                    width={width}
                    sx={{
                      borderRadius: "10px",
                    }}
                    src={elem.img}
                    alt="imageContainer"
                  />
                </Box>
                {/* text container */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    textAlign: ["center", "left"],
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Nunito",
                        fontSize: ["24px", "32px"],
                        lineHeight: ["32px", "41px"],
                        fontWeight: "700",
                        color: "#303030",
                      }}
                    >
                      {elem.heading}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: ["0px 15px", "0px"],
                      textAlign: ["justify", "left"],
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Nunito",
                        fontSize: ["18px", "24px"],
                        lineHeight: ["30px", "36px"],
                        color: "#454040",
                      }}
                    >
                      {elem.subHeading}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WhyVisit;
