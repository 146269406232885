
/* eslint-disable */
import { Container, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react';

function ContactMap() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const height = isMobile ? "340px" : "600px";
    useEffect(() => {
        const ifameData = document.getElementById('iframeId');
        const lat = 19.384893;
        const lon = 72.823484;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
    });
    return (
        <Container>

            <Box>
                <iframe
                    id="iframeId"
                    height={height}
                    width="100%"
                    style={{ border: 'none', borderRadius: '20.724px' }}
                // ref={iframeRef}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                // className="iframeWithBorder"
                />
            </Box>
        </Container>
    );
}

export default ContactMap;
