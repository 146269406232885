import React, { useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import axiosInstance from "../../utils/axios";

const backgroundImageUrl =
  "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Back.png";

const styles = {
  root: {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    padding: "30px 0px 100px 0px",
  },
};

const Header = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSend = async () => {
    if (!validateEmail(email)) {
      setError("Invalid email address");
      setToastSeverity("error");
      setToastMessage("Invalid email address");
      setOpenToast(true);
      return;
    }

    try {
      const payload = { email: email };
      await axiosInstance.post("/medical-tourism", payload).then((response) => {
        setToastSeverity("success");
        setToastMessage(`${response.data.message}`);
        setOpenToast(true);
        setEmail("");
      });
    } catch (error) {
      setToastSeverity("error");
      setToastMessage("Invalid email address");
      setOpenToast(true);
    }
  };

  return (
    <Box sx={styles.root}>
      {/* Logo Fields */}
      <Box
        sx={{
          width: "80%",
          height: "84px",
          margin: "26px 0px 30px 0px",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            height: "100%",
          }}
        >
          <img
            height={"100%"}
            src="https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Logo.png"
            alt="logo"
          />
        </Box>
      </Box>
      {/* Title */}
      <Box
        sx={{
          width: ["100%", "70%"],
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: ["24px", "60px"],
            lineHeight: ["32px", "73px"],
            textAlign: "center",
            fontWeight: "800",
            padding: "0px 10px",
          }}
        >
          Your Complete Manual For Overseas Dental Procedures
        </Typography>
      </Box>
      {/* input mail and Button */}
      <Box
        sx={{
          width: ["90%", "70%"],
          display: "flex",
          flexDirection: ["column", "row"],
          justifyContent: "center",
          gap: 1,
          margin: "30px 0px",
        }}
      >
        <Box>
          <TextField
            id="outlined-start-adornment"
            placeholder="Enter your email ID"
            sx={{ m: 1, width: ["95%", "50ch"] }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError("");
            }}
            error={!!error}
            helperText={error}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="success"
            sx={{
              width: ["98%", "212px"],
              height: "55px",
              marginTop: "8px",
              fontFamily: "Inter",
              fontSize: "14px",
            }}
            onClick={handleSend}
          >
            Send
          </Button>
        </Box>
      </Box>
      {/* image */}
      <Box
        sx={{
          width: ["90%", "70%"],
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            height: ["auto", "auto"],
            maxWidth: ["90%", "100%"],
            mx: "auto",
          }}
        >
          <img
            height={"100%"}
            width={"100%"}
            src="https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Group+52535.png"
            alt="tourism"
          />
        </Box>
      </Box>
      {/* Toast */}
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleCloseToast} severity={toastSeverity}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Header;
