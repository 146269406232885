/* eslint-disable */
import { useState } from "react";
// import { Link,Link as RouterLink } from "react-router-dom";
// @mui
import {
  Box,
  Grid,
  Link,
  Stack,
  Divider,
  Container,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
// components
import Image from "../../components/hook-form/image";
import useResponsive from "../../components/hook-form/useResponsive";

const _socials = [
  {
    id: 1,
    value: "facebook",
    name: "FaceBook",
    icon: "https://d8zd1o3mylvwf.cloudfront.net/YOREcare+V2/Footer+Logo/Facebook-Logo.png",
    color: "#1877F2",
    path: "https://www.facebook.com/yore.care",
  },
  {
    id: 2,
    value: "instagram",
    name: "Instagram",
    icon: "https://d8zd1o3mylvwf.cloudfront.net/YOREcare+V2/Footer+Logo/Instagram-Logo.png",
    color: "#E02D69",
    path: "https://www.instagram.com/yore.care/",
  },
  {
    id: 3,
    value: "linkedin",
    name: "Linkedin",
    icon: "https://d8zd1o3mylvwf.cloudfront.net/YOREcare+V2/Footer+Logo/linkedin-Logo-.png",
    color: "#007EBB",
    path: "https://www.linkedin.com/showcase/yorecare/",
  },
  {
    id: 4,
    value: "twitter",
    name: "Twitter",
    icon: "https://d8zd1o3mylvwf.cloudfront.net/YOREcare+V2/Footer+Logo/Twitter-Logo.png",
    color: "#007EBB",
    path: "https://twitter.com/YoreCare",
  },
];

// ----------------------------------------------------------------------

const LINKS = [
  {
    id: 1,
    headline: "Contact Us",
    children: [
      { name: "+91-9172216500", href: "#" },
      { name: "YORE Care", href: "#" },
    ],
  },
  {
    id: 2,
    headline: "Address",
    children: [
      {
        name: "B/106-107, Parmanand, Ashok Nagar, Ambadi Rd, Vasai West, Maharashtra 401202",
        href: "#",
      },
    ],
  },
  {
    id: 3,
    headline: "Legal",
    children: [
      { name: "Terms of Services", href: "#" },
      { name: "Privacy Policy", href: "#" },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const [opens, setOpen] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [OpenModel, setOpenModel] = useState(false);
  const [newOpenModal, setNewOpenModal] = useState(false);
  const ismobile = useResponsive("up", "lg");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpenTerms(true);
  };
  const handleClickClose = () => {
    setOpenModel(false);
    // navigate('/')
  };
  const handleOpenModel = () => {
    setOpenModel(true);
  };
  const handleNewOpenModal = () => {
    setNewOpenModal(true);
  };

  const navigateHome = () => {
    window.open("https://yore.care/", "_blank");
  };

  return (
    <Box
      component="footer"
      sx={{
        position: "relative",
        bgcolor: "background.default",
        marginTop: [2, 0, 4],
        // border:'1px solid red'
      }}
    >
      <Divider />

      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          sx={{
            textAlign: {
              xs: "center",
              md: "left",
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Image
              src={
                "https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/Logo.png"
              }
              sx={{
                mx: { xs: "auto", md: "inherit" },
                width: [125, 200],
                textAlign: ["center", "left"],
              }}
              alt="YORE Care Logo"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography
              variant="body2"
              sx={{
                pr: { xs: 2, md: 5 },
                fontSize: "16px",
                fontFamily: "Nunito",
                width: { xs: "auto", md: "400px" },
                fontWeight: 700,
                textAlign: ["center", "left"],
              }}
            >
              Send Emails to:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                pr: { xs: 2, md: 5 },
                fontSize: "16px",
                fontFamily: "Nunito",
                width: { xs: "auto", md: "400px" },
                textAlign: ["center", "left"],
              }}
            >
              hello@yorecare.com
            </Typography>

            {ismobile && (
              <>
                {" "}
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={{ xs: "center", md: "flex-start" }}
                  sx={{
                    mt: 5,
                    mb: { xs: 5, md: 0 },
                  }}
                >
                  {_socials.map((social) => (
                    <IconButton
                      key={social.name}
                      onClick={() => window.open(social.path)}
                    >
                      <Image
                        src={social.icon}
                        alt={social.name}
                        sx={{ width: 24, height: 24 }}
                      />
                    </IconButton>
                  ))}
                </Stack>
              </>
            )}
          </Grid>
          {ismobile && (
            <Grid item xs={12} md={7}>
              <Stack
                spacing={[5]}
                justifyContent="space-between"
                direction={{ xs: "row", md: "row" }}
                // mt={5}
              >
                {LINKS.map((list) => (
                  <Stack
                    key={list.headline}
                    spacing={2}
                    alignItems={{ xs: "center", md: "flex-start" }}
                    width={"100%"}
                  >
                    <Typography
                      component="div"
                      sx={{ fontSize: [12, 18, "18px"], fontFamily: "Nunito" }}
                      fontWeight={700}
                    >
                      {list.headline}
                    </Typography>

                    {list.children.map((link) => (
                      <>
                        <Link
                          key={link.name}
                          //   component={RouterLink}
                          to={link.href}
                          onClick={() => {
                            if (link.name === "Privacy Policy")
                              handleClickOpen();
                            if (link.name === "Terms of Services")
                              handleOpenModel();
                            if (link.name === "YORE Care") navigateHome();
                          }}
                          color="inherit"
                          variant="body2"
                          sx={{
                            fontSize: [12, 18, "16px"],
                            fontFamily: "Nunito",
                            textDecoration: "none",
                            cursor: `${
                              link.name === "YORE Care" ||
                              link.name === "Privacy Policy" ||
                              link.name === "Terms of Services"
                                ? "pointer"
                                : "default"
                            }`,
                            "&:hover": {
                              color: "#4D31D7",
                            },
                          }}
                        >
                          {link.name}
                        </Link>
                      </>
                    ))}
                  </Stack>
                ))}
              </Stack>
            </Grid>
          )}

          {!ismobile && (
            <>
              <Grid sx={{ width: ["90%"] }}>
                <Stack
                  spacing={[2]}
                  // justifyContent="space-between"
                  gap={3}
                  direction={{ xs: "row", md: "row" }}
                  mt={5}
                >
                  {LINKS.map((list) => {
                    if (list.id === 1 || list.id === 2) {
                      return (
                        <>
                          <Stack
                            key={list.headline}
                            spacing={2}
                            alignItems={{ xs: "", md: "flex-start" }}
                            width={"100%"}
                          >
                            <Typography
                              component="div"
                              sx={{
                                fontSize: [12, 18, "16px"],
                                fontFamily: "Nunito",
                              }}
                              fontWeight={700}
                              textAlign={"left"}
                            >
                              {list.headline}
                            </Typography>

                            {list.children.map((link) => (
                              <>
                                <Link
                                  key={link.name}
                                  //   component={RouterLink}
                                  to={link.href}
                                  onClick={() => {
                                    if (link.name === "Privacy Policy")
                                      handleClickOpen();
                                    if (link.name === "Terms of Services")
                                      handleOpenModel();
                                    if (link.name === "YORE Care")
                                      navigateHome();
                                  }}
                                  color="inherit"
                                  variant="body2"
                                  sx={{
                                    fontSize: [12, 18, "16px"],
                                    fontFamily: "Nunito",
                                    textAlign: "left",
                                    textDecoration: "none",
                                    cursor: `${
                                      link.name === "YORE Care" ||
                                      link.name === "Privacy Policy" ||
                                      link.name === "Terms of Services"
                                        ? "pointer"
                                        : "default"
                                    }`,
                                    "&:hover": {
                                      color: "#4D31D7",
                                    },
                                  }}
                                >
                                  {link.name}
                                </Link>
                              </>
                            ))}
                          </Stack>
                        </>
                      );
                    }
                  })}
                </Stack>
              </Grid>
            </>
          )}
          {!ismobile && (
            <Grid sx={{ width: "90%" }}>
              <Stack spacing={[2]} mt={5}>
                {LINKS.map((list) => {
                  if (list.id === 3) {
                    return (
                      <>
                        <Stack
                          key={list.headline}
                          spacing={2}
                          alignItems={"flex-start"}
                        >
                          <Typography
                            component="div"
                            sx={{
                              fontSize: [12, 18, "16px"],
                              fontFamily: "Nunito",
                            }}
                            fontWeight={700}
                          >
                            {list.headline}
                          </Typography>
                          {list.children.map((link) => (
                            <Box sx={{ width: "50%", textAlign: "left" }}>
                              <Link
                                key={link.name}
                                // component={RouterLink}
                                to={link.href}
                                onClick={() => {
                                  if (link.name === "Privacy Policy")
                                    handleClickOpen();
                                  if (link.name === "Terms of Services")
                                    handleOpenModel();
                                  if (link.name === "YORE Care") navigateHome();
                                }}
                                color="inherit"
                                variant="body2"
                                sx={{
                                  fontSize: [12, 18, "16px"],
                                  fontFamily: "Nunito",
                                  textDecoration: "none",
                                  cursor: `${
                                    link.name === "YORE Care" ||
                                    link.name === "Privacy Policy" ||
                                    link.name === "Terms of Services"
                                      ? "pointer"
                                      : "default"
                                  }`,
                                  "&:hover": {
                                    color: "#4D31D7",
                                  },
                                }}
                              >
                                {link.name}
                              </Link>
                            </Box>
                          ))}
                        </Stack>
                      </>
                    );
                  }
                })}
              </Stack>
            </Grid>
          )}

          <Box sx={{ width: "120%" }}>
            {!ismobile && (
              <>
                <Divider sx={{ width: "100%", mt: 3 }} />
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={{ xs: "flex-start", md: "flex-start" }}
                  sx={{
                    mt: 3,
                    mb: { xs: 5, md: 0 },
                  }}
                >
                  {_socials.map((social) => (
                    <IconButton
                      key={social.name}
                      onClick={() => window.open(social.path)}
                    >
                      <Image
                        src={social.icon}
                        alt={social.name}
                        sx={{ width: 24, height: 24 }}
                      />
                    </IconButton>
                  ))}
                </Stack>
              </>
            )}
          </Box>
        </Grid>
        <Stack direction="row">
          <Typography
            variant="caption"
            component="div"
            sx={{
              mt: [-1, 5],
              pb: 5,
              textAlign: { xs: "left", md: "left" },
              fontSize: [12, 20, "16px"],
              fontFamily: "Nunito",
            }}
          >
            © 2024. All rights reserved by{" "}
            <Link
              style={{ fontFamily: "Nunito", fontSize: [12, 20, "16px"] }}
              href="https://adsindia.tech/"
              target="blank"
            >
              Advance Digital Solutions.
            </Link>{" "}
            Made with{" "}
            <span>
              <FavoriteIcon sx={{ width: 15, height: 15, color: "red" }} />{" "}
            </span>{" "}
            In India
          </Typography>
        </Stack>
      </Container>
      {/*  PRIVACY POLICY */}
      <Dialog
        open={opens}
        maxWidth="xl"
        onClose={handleClose}
        sx={{ paddingLeft: "16px" }}
      >
        <DialogTitle>
          <b style={{ fontSize: "25px" }}>
            Health Privacy & Confidentiality Statement
          </b>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We recognize the importance of protecting the confidentiality of
            medical and health-related information and other personal data and
            we are committed to protecting it in accordance with all applicable
            legal requirements. This statement discusses how we acquire, use and
            disclose your personal health and other information and our policies
            and practices for keeping information private and secure. We urge
            you to review this policy carefully and to check back periodically
            for revisions. You can tell when this policy has been modified by
            looking at the date that it was last updated at the top of this
            page. <br />
            <br />
            If you use our services, you acknowledge and agree to the terms of
            this policy, as it may be modified from time to time as provided
            above.
          </DialogContentText>
          <br />
          <DialogContentText>
            <b>Personally Identifiable Information </b>
          </DialogContentText>
          <br />
          <DialogContentText>
            All health information that identifies you is referred to as
            “personally identifiable information” and is shared with our network
            of hospitals to provide you with a health service solution. Your
            personally identifiable information will be used only as necessary
            to provide the services that you request, or as otherwise required
            by law. ​<br />
            <br />
            We have implemented reasonable precautions to ensure that your
            personally identifiable information is retained in a secure fashion
            and protected against loss, misuse and unauthorized access,
            disclosure, alteration or destruction. We utilize encryption,
            firewalls and intrusion detection systems to keep your data safe. We
            only permit authorized employees to access your personally
            identifiable information and they may use it only for authorized
            business purposes. As effective as our safeguards may be, no
            security system is completely impenetrable, so we cannot guarantee
            the absolute security of our database, nor can we guarantee that the
            personally identifiable information you supply won’t be intercepted
            while being transmitted to us over the Internet.
            <br />
            <br />
            We will not sell or otherwise transfer your personally identifiable
            information to another party for any purpose other than to provide
            the services that you request. In the unlikely event that assets
            relating to our site are sold or transferred to another party, your
            personally identifiable information may be transferred to this
            acquiring entity. Finally, we may disclose your personally
            identifiable information to third parties if we believe that we are
            required to do so in order to comply with law (including in response
            to court orders and subpoenas).
          </DialogContentText>
          <br />
          <DialogContentText>
            <b>Non Personally-Identifiable Information </b>
          </DialogContentText>
          <br />
          <DialogContentText>
            If you do nothing during your visit but browse through the web site,
            do searches, read pages, or download information, we will
            automatically gather and store certain information about your visit
            that does not identify you personally (“non personally-identifiable
            information”). Non personally-identifiable information may include
            IP address, the domain name and browser that you use to access the
            internet, the pages that you visited on our site and the address of
            the website that you visited immediately prior to visiting our site.
            We use non personally-identifiable information to help us make our
            site more useful to visitors — to learn about the number of visitors
            to our site, what part of the site they are interested in, how long
            they use the site, and the types of technology our visitors use.
            <b>
              {" "}
              WE DO NOT TRACK OR RECORD INFORMATION ABOUT THE IDENTITY OF
              INDIVIDUALS AND THEIR VISITS TO OUR WEBSITE.
            </b>{" "}
            Sometimes we write a small file on the user’s computer called a
            “session cookie.” Session cookies automatically expire when users
            leave a Web site; session cookies retain information only during the
            session or for the purpose of completing a particular online
            transaction, without any capacity to track users over time and
            across different web sites.
            <br />
            <br />
            Our website includes links to other websites (“linked sites”). In
            providing access to these linked sites, we are by no means endorsing
            their products or services. We are not responsible for the privacy
            practices or content of the linked sites and expressly disclaim all
            responsibility and liability associated with your use of the linked
            sites. We recommend that you review the privacy statements posted on
            those sites and understand their policies on using and disclosing
            personal information.
            <br />
            <br />
            We do not collect, store, or disclose information about children
            under the age of 18 without parental consent. If we receive
            information from a child that we know to be under the age of 18, we
            will use that information only to inform the child that we require
            parental consent. If you have questions or concerns regarding our
            Health Privacy and Confidentiality Statement or our practices,
            please contact <b>hello@yorecare.com</b>
          </DialogContentText>
          <br />
          <br />
        </DialogContent>
      </Dialog>
      {/* Terms of Service */}
      <Dialog
        open={OpenModel}
        maxWidth="xl"
        onClose={handleClickClose}
        sx={{ paddingLeft: "16px" }}
      >
        <DialogTitle>
          <b style={{ fontSize: "30px" }}>TERMS OF USE </b>
          <IconButton
            aria-label="close"
            onClick={handleClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContentText>
          <DialogContentText sx={{ paddingLeft: 3, textAlign: "justify" }}>
            <b> PLEASE READ THESE TERMS OF USE CAREFULLY </b>
          </DialogContentText>
          <br />
          <DialogContentText sx={{ paddingLeft: 3 }}>
            We urge you to review these Terms of Use carefully. YORE Care
            reserves the right to change these Terms of Use at any time by
            posting changes online. You should check back periodically for
            revisions. You will be able to tell when these Terms of Use have
            been modified by looking at the date that they were last updated at
            the top of this page. Your continued use of the Site after changes
            are posted constitutes acceptance of the modified Terms of Use.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            <b>Medical Emergencies </b>
          </DialogContentText>
          <br />
          <DialogContentText sx={{ paddingLeft: 3 }}>
            Our website is not intended for use in the event of a medical
            emergency. Call your health care provider, “911,” or the emergency
            number in your country immediately in case of a medical exigency.
            <br />
            <br />
            YORE Care is not liable for any emergency, complication, or
            treatment requirement of any nature, arising from the patient’s
            procedure due to their present or past illness, or the side effects
            of any medication, or lack of follow-up care, or inadequate medical
            care, or negligence and incompetence.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            <b>The Site does not contain Medical Advice </b>
          </DialogContentText>
          <br />
          <DialogContentText sx={{ paddingLeft: 3 }}>
            YORE Care provide qualified healthcare physicians before you select
            a new treatment, or doctor, or a medical facility we will provide
            you full information of the same. You must seek the advice of your
            doctor or a qualified healthcare provider if you have any questions
            or doubts regarding any symptoms or medical condition.
            <br />
            <br /> Do not disregard, avoid, or delay in gaining medical advice
            from your physician or other qualified healthcare providers because
            of something you have read on this website. All information on this
            website is provided in good faith. The decision to have a procedure
            should be based on the user’s own independent research of the
            treatment, hospital or surgery centre and the doctor or surgeon.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            Patient Testimonials, Before-and-After Pictures, Videos and Client
            Experiences
          </DialogContentText>
          <br />
          <DialogContentText sx={{ paddingLeft: 3 }}>
            WE DO NOT GUARANTEE ANY SURGICAL OUTCOMES (including and not
            restricted to results of weight loss surgery, dental work, cosmetic
            surgery and other treatments). All patient reviews, testimonials,
            and pictures are of real clients who have availed our dental
            services demonstrated in our testimonials are in no way typical of
            individuals and may vary.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            Accuracy of information
          </DialogContentText>
          <DialogContentText sx={{ paddingLeft: 3 }}>
            While YORE CARE makes reasonable efforts to ensure that the content
            that it provides on the Site is accurate at the time provided, YORE
            CARE makes no representation or warranty about the accuracy of the
            content. The information may be out of date at the time of your
            access or could also include technical inaccuracies or typographical
            errors. We are not liable for any error or omission in, or any
            failure to update the Site content. The website content has not been
            reviewed, approved or compiled by any medical professional. Neither
            is the website content based on feedback from medical expert or
            experts. Decisions made based on the information on the Site are the
            sole responsibility of the visitor.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            Jurisdiction
          </DialogContentText>
          <br />

          <DialogContentText sx={{ paddingLeft: 3 }}>
            You expressly agree that exclusive jurisdiction for any dispute with
            YORE Care, or in any way relating to your use of the YORE Care Site,
            resides in the courts of the Mumbai and you further agree and
            expressly consent to the exercise of personal jurisdiction in the
            courts of the State of Texas in connection with any such dispute
            including any claim involving YORE CARE employees or officers.
          </DialogContentText>
          <br />

          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            Disclaimers
          </DialogContentText>
          <br />

          <DialogContentText sx={{ paddingLeft: 3 }}>
            ACCESS TO THE SITE AND THE INFORMATION CONTAINED HEREIN IS PROVIDED
            “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS
            OR IMPLIED. YORE CARE DOES NOT WARRANT THE ACCURACY, COMPLETENESS OR
            TIMELINESS OF THE MATERIALS ON THIS SITE AND TO THE FULLEST EXTENT
            PERMISSIBLE PURSUANT TO APPLICABLE LAW, YORE CARE DISCLAIMS ALL
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT
            LIMITED TO ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE OR NON- INFRINGEMENT, WITH RESPECT TO ANY
            INFORMATION OBTAINED THROUGH THE SITE.
            <br />
            <br />
            WITHOUT LIMITING THE FOREGOING, YORE CARE DOES NOT WARRANT THAT
            ACCESS TO THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT
            DEFECTS, IF ANY, WILL BE CORRECTED; NOR DOES YORE CARE MAKE ANY
            REPRESENTATIONS ABOUT THE ACCURACY, RELIABILITY, CURRENCY, QUALITY,
            COMPLETENESS, USEFULNESS, PERFORMANCE, SECURITY, LEGALITY OR
            SUITABILITY OF THE SITE OR ANY OF THE INFORMATION CONTAINED THEREIN.
            YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE AND YOUR RELIANCE UPON
            ANY OF ITS CONTENTS IS AT YOUR SOLE RISK.
            <br />
            <br />
            YOU SHALL BE SOLELY AND FULLY RESPONSIBLE FOR ANY DAMAGE TO THE SITE
            OR ANY COMPUTER SYSTEM, ANY LOSS OF DATA, OR ANY IMPROPER USE OR
            DISCLOSURE OF INFORMATION ON THE SITE CAUSED BY YOU. YORE CARE
            CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES
            OR LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS
            INFRASTRUCTURE, OR THE INTERNET OR FOR YOUR MISUSE OF ANY OF ADVICE,
            IDEAS, INFORMATION, INSTRUCTIONS OR GUIDELINES ACCESSED THROUGH THE
            SITE.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            Limitation of Liability
          </DialogContentText>
          <br />
          <DialogContentText sx={{ paddingLeft: 3 }}>
            IN THE EVENT OF ANY PROBLEM WITH THE SITE OR ANY OF ITS CONTENT, YOU
            AGREE THAT YOUR SOLE REMEDY IS TO CEASE USING THE SITE. UNDER NO
            CIRCUMSTANCES SHALL YORE CARE OR ANY YORE CARE LICENSOR OR SUPPLIER
            BE LIABLE IN ANY WAY FOR YOUR USE OF THE SITE OR ANY OF ITS CONTENT,
            INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY
            CONTENT, ANY INFRINGEMENT BY ANY CONTENT OF THE INTELLECTUAL
            PROPERTY RIGHTS OR OTHER RIGHTS OF THIRD PARTIES, OR FOR ANY LOSSES
            OR DAMAGES OF ANY KIND ARISING DIRECTLY OR INDIRECTLY OUT OF THE
            USE, INABILITY TO USE, OR THE RESULTS OF USE OF THE SITE, AND ANY
            WEBSITE LINKED TO THE SITE, OR THE MATERIALS OR INFORMATION
            CONTAINED ON ANY OR ALL SUCH SITES.
            <br />
            <br />
            UNDER NO CIRCUMSTANCES SHALL YORE CARE OR ITS LICENSORS OR SUPPLIERS
            OR PROVIDERS BE LIABLE FOR ANY PUNITIVE, EXEMPLARY, CONSEQUENTIAL,
            INCIDENTAL, INDIRECT OR SPECIAL DAMAGES (INCLUDING, WITHOUT
            LIMITATION, ANY PERSONAL INJURY, LOST PROFITS, BUSINESS
            INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR COMPUTER OR
            OTHERWISE) ARISING FROM OR IN CONNECTION WITH YOUR USE OF THE SITE,
            WHETHER UNDER A THEORY OF BREACH OF CONTRACT, NEGLIGENCE, STRICT
            LIABILITY OR OTHERWISE, EVEN IF WE OR THEY HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.
          </DialogContentText>
          <br />
          <DialogContentText sx={{ fontWeight: 700, paddingLeft: 3 }}>
            Indemnity
          </DialogContentText>
          <br />
          <DialogContentText sx={{ paddingLeft: 3 }}>
            You agree to defend, indemnify, and hold YORE CARE, its officers,
            directors, employees, agents, advisors, licensors, and suppliers,
            harmless from and against any claims, actions or demands, injuries
            (physical or psychological), liabilities and settlements including
            without limitation, reasonable legal and accounting fees, resulting
            from, or alleged to result from your violation of these Terms of
            Use.
          </DialogContentText>
          <br />
          <br />
          <br />
        </DialogContentText>
      </Dialog>
    </Box>
  );
}
