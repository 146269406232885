import React from 'react';
import Header from './pages/Header/Header';
import { Box } from '@mui/material';
import Carosuel from './pages/Carosuel/Carosuel';
import WhyVisit from './pages/WhyVisit/WhyVisit';
import Testimonial from './pages/Testimonial/Testimonial';
import Faq from './pages/FAQ/Faq';
import RegistrationForm from './pages/Form/RegistrationForm';
import ContactMap from './pages/Form/ContactMap';
import Footer from './pages/Footer/Footer';

function App() {
  return (
    <Box>
      <Header />
      <Carosuel />
      <WhyVisit />
      <Testimonial />
      <Faq />
      <Box
        sx={{
          py: 5,
          width: ["90%", "70%"],
          margin: 'auto',
          backgroundColor: '#F8F9FF',
          borderRadius: '20px',
          // padding: ['40px 15px;', '60px'],
          mt: '164px',
          mb: '64px'
        }}
        gap={["30px", "16px"]}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
      >
        <RegistrationForm />
        <ContactMap />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
