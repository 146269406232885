import React, { useState } from "react";
// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Card,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const _faqs = [
  {
    id: 1,
    heading: "Is opting for dental treatment with YORE Care a wise decision?",
    detail:
      "For those without dental insurance, seeking treatment abroad is a financially savvy choice. Unlock savings of up to 70% on dental procedures by scheduling an appointment with us. Benefit from our in-house laboratory support and state-of-the-art equipment, ensuring a cost-effective and high-quality dental experience.",
  },
  {
    id: 2,
    heading: "Is YORE Care safe for Dental treatments in India?",
    detail:
      "YORE Care provide you with a personalised dental care provider at the point of care. With our imaging and communication technologies, we extend high-tech monitoring and convenient care options to our international patients- thereby delivering a memorable experience in our country. Drop an email or visit our website to schedule your appointment today",
  },
  {
    id: 3,
    heading: "Is Opting for Dental Treatment with YORE Care Worth It?",
    detail:
      "For those without dental insurance, seeking treatment abroad is a financially savvy choice. Unlock savings of up to 70% on dental procedures by scheduling an appointment with us. Benefit from our in-house laboratory support and state-of-the-art equipment, ensuring a cost-effective and high-quality dental experience.",
  },
  {
    id: 4,
    heading: "How to Reach the YORE Care Team ?",
    detail:
      "If you haven't connected with us through our website yet, do so now, and we'll make an effort to understand your concerns and the necessary course of action. Based in Mumbai, a city with much to offer, we believe in enhancing your smile. YORE Care provides package deals that consolidate the costs of your dental work. Feel free to reach out and discover the benefits of our comprehensive dental solutions.",
  },
  {
    id: 5,
    heading: "Why Choose YORE Care for Dental work in India?",
    detail:
      "For those deferring dental visits due to prohibitively expensive treatments in their home country, India is the solution. YORE Care delivers a premium dental experience, we have trained team of specialized dentists, dental assistants, devoted patient coordinators, cutting-edge dental technology, and personalized treatment plans.",
  },
];

const Faq = () => {
  const [showAll, setShowAll] = useState(false);

  const displayedFaqs = showAll ? _faqs : _faqs.slice(0, 5);
  return (
    <>
      <Box
        sx={{
          margin: "auto",
          marginTop: "100px",
        }}
      >
        <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: ["24px", "48px"],
              lineHeight: ["32px", "41px"],
              fontWeight: "700",
              color: "#303030",
              margin: ["20px 0px 40px 0px", "20px 0px 60px 0px"],
            }}
          >
            Frequently Asked Questions
          </Typography>
        </Box>
        <Box sx={{ width: ["90%", "75%"], margin: "auto" }}>
          {displayedFaqs.map((accordion) => (
            <Card
              sx={{
                marginTop: 3,
                backgroundColor: "#F8F8FF",
                borderRadius: "10px",
              }}
              boxShadow=" 0px 0px 130px -25px rgba(77,19,215,0.4)"
            >
              <Accordion key={accordion.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ height: 80, backgroundColor: "#F8F8FF" }}
                >
                  <Typography
                    sx={{
                      fontSize: ["14px", 25, 20],
                      fontFamily: "Nunito",
                      fontWeight: "700",
                      lineHeight: 1.5,
                    }}
                  >
                    {accordion.heading}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ backgroundColor: "#F8F8FF" }}>
                  <Typography
                    lineHeight={2}
                    fontSize={[14, 18, 17]}
                    letterSpacing={0}
                    sx={{ fontFamily: "Nunito", lineHeight: "24px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: accordion.detail }}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Card>
          ))}
          {/* {!showAll ? (
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: "balooBhai2",
                fontSize: ["12px", "20px"],
                mt: [2, 5],
                width: ["98%", "20%", "10%"],
                margin: "auto",
                cursor: "pointer",
                color: "#4d31d7",
                // border:'1px solid red',
                textAlign: ["right", "right"],
              }}
              onClick={() => setShowAll(true)}
            >
              View More
            </Typography>
          ) : (
            <>
              <Typography
                onClick={() => setShowAll(false)}
                variant="subtitle1"
                sx={{
                  fontFamily: "balooBhai2",
                  fontSize: ["12px", "20px"],
                  mt: [2, 5],
                  width: ["98%", "10%"],
                  margin: "auto",
                  cursor: "pointer",
                  color: "#4d31d7",
                  textAlign: "center",
                }}
              >
                View Less
              </Typography>
            </>
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default Faq;
