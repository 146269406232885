/* eslint-disable */
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import useResponsive from "../../components/hook-form/useResponsive";

const testimonial = [
  {
    name: "Mike, USA",
    description:
      "YORE Care provided me with an unparalleled dental experience. The clinic's top-notch facilities, impeccable cleanliness, and stellar sterilization practices truly set it apart. The doctors and staff were consistently available for my inquiries. I wholeheartedly recommend this clinic without any reservations.",
  },
  {
    name: "Emily",
    description:
      "I recently had one of the best dental experiences at YORE Care. The expertise of the doctors and the supportive staff were outstanding. They were always just a call away, promptly addressing my questions. I confidently recommend this clinic to anyone seeking exceptional dental care.",
  },
  {
    name: "William",
    description:
      "YORE Care exceeded my expectations, providing me with an exceptional dental experience. The doctors and staff demonstrated expertise in their work, and their availability for my queries was commendable. I love the results and I highly recommend them.",
  },
];

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
};

const Testimonial = () => {
  const isDesktop = useResponsive("up", "md");
  return (
    <>
      <Box sx={{ margin: "auto", marginTop: "100px" }}>
        {/* header */}
        <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: ["26px", "48px"],
              lineHeight: "41px",
              fontWeight: "700",
              color: "#303030",
            }}
          >
            Client Testimonial
          </Typography>
        </Box>
        {/* subHeading */}
        <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: ["18px", "20px"],
              lineHeight: "31px",
              color: "#454040",
              padding: ["0px 5%", "0px 20% 0px 20%"],
            }}
          >
            Discover What Our Satisfied Clients Are Saying About Us
          </Typography>
        </Box>
        {/* video */}
        <Box
          sx={{
            width: ["347px", "860px"],
            height: ["212px", "500px"],
            margin: "auto",
          }}
        >
          <video
            height="100%"
            width="100%"
            src="https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Medical+turisam/testimonial+video.mp4"
            controls
          />
        </Box>
        {/* testimonial */}
        <Box
          sx={{
            width: "90%",
            margin: "auto",
            // background: ["var(--Neutral---Zircon, #F8F9FF)", "none"],
            borderRadius: "16px",
          }}
        >
          <Box sx={{ mt: "32px" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "32px",
                fontFamily: "Nunito",
                marginBottom: "16px",
              }}
            >
              What our users say?
            </Typography>
          </Box>
          {isDesktop ? (
            <>
              <Slider {...settings}>
                {testimonial.map((text) => {
                  return (
                    <>
                      <Box
                        sx={{
                          width: "60%",
                          margin: "auto",
                          borderRadius: "20px",
                          padding: "18.71px 16.71px 12.874px 15.71px",
                          // border: '0.5px solid #DFE3E8',
                          background: [
                            "var(--Neutral---Zircon, #F8F9FF)",
                            "none",
                          ],
                          mt: "32px",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "40px",
                            marginBottom: "18px",
                            color: "#637381",
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <Box sx={{ width: 40, height: 40, marginTop: "-5%" }}>
                            <img
                              src="https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Air+quotes+1.png"
                              alt="quotes 1"
                              style={{ width: 40, height: 40 }}
                            />
                          </Box>{" "}
                          {text.description}{" "}
                          <Box sx={{ width: 40, height: 40, marginTop: "14%" }}>
                            <img
                              src="https://d8zd1o3mylvwf.cloudfront.net/YoreCare+Website+V2/Air+quotes+2.png"
                              alt="quotes 2"
                              style={{ width: 40, height: 40 }}
                            />
                          </Box>{" "}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15.328px",
                            marginBottom: "24px",
                            color: "#38434E",
                          }}
                        >
                          {text.name}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Slider>
            </>
          ) : (
            <>
              <Slider {...settings}>
                {testimonial.map((text) => {
                  return (
                    <>
                      <Box
                        sx={{
                          width: "98%",
                          height: "280px",
                          margin: "auto",
                          borderRadius: "20px",
                          // padding: "0px 10px 0px 10px",
                          // background: "var(--Neutral---Zircon, #F8F9FF)",
                          border: "1px solid teal",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "90%",
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "12.773px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15.328px",
                            marginBottom: "8px",
                            paddingTop: "30px",
                            color: "#637381",
                            display: "flex",
                            gap: "2px",
                            // border: "1px solid red",
                            margin: "auto",
                          }}
                        >
                          <Box sx={{ width: 10, height: 10 }}>
                            <img
                              height="100%"
                              width="100%"
                              src="https://yore-care.s3.ap-south-1.amazonaws.com/YoreCare+Website+V2/Air+quotes+1.png"
                              alt="quotes 1"
                            />
                          </Box>{" "}
                          <Typography
                            sx={{ width: "90%", textAlign: "justify" }}
                          >
                            {text.description}
                          </Typography>{" "}
                          <Box
                            sx={{ width: 10, height: 10, marginTop: "12.5rem" }}
                          >
                            <img
                              height="100%"
                              width="100%"
                              src="https://yore-care.s3.ap-south-1.amazonaws.com/YoreCare+Website+V2/Air+quotes+2.png"
                              alt="quotes 2"
                            />
                          </Box>{" "}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontFamily: "Nunito",
                            fontSize: "12.773px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "15.328px",
                            marginBottom: "8px",
                            color: "#38434E",
                          }}
                        >
                          {text.name}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Slider>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Testimonial;
