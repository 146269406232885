/* eslint-disable */
import React, { useState } from "react";
import { Alert, Box, Button, Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import axiosInstance from "../../utils/axios";

const RegistrationForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState(new FormData());
  const [formErrors, setFormErrors] = useState({});
  const [editable, setEditable] = useState(true);
  const [success, setSuccess] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.get("Name")) {
      errors.Name = "Name is required";
    }
    if (!formData.get("email")) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.get("email"))
    ) {
      errors.email = "Invalid email address";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.get("email"))
    ) {
      const emailParts = formData.get("email").split("@");
      if (emailParts[1].indexOf(".") === -1) {
        errors.email = "Invalid email address";
      }
    }
    if (!formData.get("phoneNumber")) {
      errors.phoneNumber = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.get("phoneNumber"))) {
      errors.phoneNumber = "Invalid contact number";
    } else if (/(\d)\1{4,5}/.test(formData.get("phoneNumber"))) {
      errors.phoneNumber =
        "Invalid contact number: consecutive digits repeated 5-6 times";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        name: formData.get("Name"),
        email: formData.get("email"),
        mobile: formData.get("phoneNumber"),
        message: formData.get("message"),
      };
      axiosInstance
        .post("/medical-tourism", data)
        .then((response) => {
          setToastSeverity("success");
          setToastMessage(`${response.data.message}`);
          setOpenToast(true);
        })
        .catch((err) => {
          setToastSeverity("error");
          setToastMessage("Invalid email address");
          setOpenToast(true);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formData.set(name, value);
    setFormData(formData);
  };

  return (
    <>
      <Box
        sx={{
          width: ["94%", "70%"],
          margin: "auto",
          padding: "0px 10px",
        }}
      >
        <Typography
          sx={{
            // textAlign: 'center',
            fontWeight: 700,
            fontSize: ["24px", "40px", "40px"],
            fontFamily: "Nunito",
            mb: 1,
          }}
        >
          Want FREE Consultation
        </Typography>
        <Typography
          sx={{
            fontSize: ["16px", "28px"],
            fontFamily: "Nunito",
            fontWeight: 400,
            mb: 3,
          }}
        >
          Provide us your details.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12} md={6}>
            <Box
              rowGap={1}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
              // marginTop="8px"
            >
              <TextField
                // label="Name"
                placeholder="Enter your Full Name"
                name="Name"
                id="outlined-basic"
                variant="outlined"
                onChange={handleChange}
                error={formErrors.Name}
                helperText={formErrors.Name}
                sx={{
                  marginTop: "8px",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                }}
                // disabled={editable}
              />

              <TextField
                sx={{
                  marginTop: "8px",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                }}
                name="phoneNumber"
                placeholder="Enter your Contact Number"
                onChange={handleChange}
                error={formErrors.phoneNumber}
                helperText={formErrors.phoneNumber}
                id="outlined-basic"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                sx={{
                  marginTop: "8px",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                }}
                placeholder="Enter your Email Address"
                onChange={handleChange}
                error={formErrors.email}
                helperText={formErrors.email}
                name="email"
                id="outlined-basic"
                variant="outlined"
              />

              <TextField
                placeholder="Enter your Message"
                name="message"
                id="outlined-basic"
                variant="outlined"
                onChange={handleChange}
                error={formErrors.specialization}
                helperText={formErrors.specialization}
                sx={{
                  marginTop: "8px",
                  fontFamily: "Nunito",
                  fontSize: "16px",
                }}
                // disabled={editable}
              />
            </Box>

            <Stack spacing={3} sx={{ marginTop: "25px" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  height: { xs: "46px", md: "50px" },
                  width: { xs: "100%", md: "100%" },
                  background: "#00AB55",
                  "&:hover": {
                    background: "#00AB55",
                  },
                  fontSize: "18px",
                  fontFamily: "Nunito",
                  margin: { xs: "auto", md: 0 },
                  // marginTop:'44px',
                }}
              >
                Submit Now
              </LoadingButton>
            </Stack>
          </Grid>
        </form>
        {/* Toast */}
        <Snackbar
          open={openToast}
          autoHideDuration={2000}
          onClose={handleCloseToast}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={handleCloseToast} severity={toastSeverity}>
            {toastMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default RegistrationForm;
